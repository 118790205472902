import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { currency, date, floatWithoutComma, time, guid } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';
import Autocomplete from "react-google-autocomplete";
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { MarkerWithInfoWindow } from 'components/MarkerWithInfoWindow';
import { useEditBoard, useDeleteBoard, useAddBoardList, useBoardLists, useEditBoardList, useDeleteBoardList, usePositionBoardList, useAddBoardListCard, useEditBoardListCard, useDeleteBoardListCard, usePositionBoardListCard, useEditBoardListCardNetworks } from 'hooks/boards.hook';
import Multiselect from 'multiselect-react-dropdown';
import { ManageBoardNetworks } from './ManageBoardNetworks';

export { ManageBoards };

function ManageBoards(props) {
  const defaultPosition = { lat: 43.7153176, lng: -79.5178468 };
  const [validated, setValidated] = useState(false);
  const [showEditBoardDialog, setShowEditBoardDialog] = useState(false);
  const [showDeleteBoardDialog, setShowDeleteBoardDialog] = useState(false);
  const [showAddBoardListDialog, setShowAddBoardListDialog] = useState(false);
  const [showEditBoardListDialog, setShowEditBoardListDialog] = useState(false);
  const [showDeleteBoardListDialog, setShowDeleteBoardListDialog] = useState(false);
  const [showAddBoardListCardDialog, setShowAddBoardListCardDialog] = useState(false);
  const [showEditBoardListCardDialog, setShowEditBoardListCardDialog] = useState(false);
  const [showDeleteBoardListCardDialog, setShowDeleteBoardListCardDialog] = useState(false);
  const [showBoardListMapDialog, setShowBoardListMapDialog] = useState(false);
  const [showViewerDialog, setShowViewerDialog] = useState(false);

  const [boardListCardComplete, setBoardListCardComplete] = useState(false);
  const [boardListCardNotify, setBoardListCardNotify] = useState(false);
  const [boardListCardShareable, setBoardListCardShareable] = useState(false);
  const [boardListCardRepeat, setBoardListCardRepeat] = useState(false);
  const [boardListCardRepeatRemind, setBoardListCardRepeatRemind] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);
  const [showRepeatRemind, setShowRepeatRemind] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const [cardPrice,setCardPrice] = useState("");
  const [selectedBoardList, setSelectedBoardList] = useState(0);
  const [selectedBoardListCard, setSelectedBoardListCard] = useState(0);
  const [location,setLocation] = useState("");
  const [search, setSearch] = useState("");

  const [boardLists, setBoardLists] = useState([]);
  const { data: boardListsData, isError: boardListsIsError, error: boardListsError, isLoading: boardListsIsLoading } = useBoardLists(props.selectedBoard.id);
  useEffect(() => { 
    if (boardListsData !== undefined) { 
      setBoardLists(boardListsData); 
    }
  }, [boardListsData]);

  const editBoardMutation = useEditBoard();
  const deleteBoardMutation = useDeleteBoard();
  const addBoardListMutation = useAddBoardList();
  const editBoardListMutation = useEditBoardList();
  const deleteBoardListMutation = useDeleteBoardList();
  const positionBoardListMutation = usePositionBoardList();
  const addBoardListCardMutation = useAddBoardListCard();
  const editBoardListCardMutation = useEditBoardListCard();
  const deleteBoardListCardMutation = useDeleteBoardListCard();
  const positionBoardListCardMutation = usePositionBoardListCard();
  const editBoardListCardNetworksMutation = useEditBoardListCardNetworks();
  
  const handleEditBoardClose = () => setShowEditBoardDialog(false);
  const handleDeleteBoardClose = () => setShowDeleteBoardDialog(false);
  const handleAddBoardListClose = () => setShowAddBoardListDialog(false);
  const handleEditBoardListClose = () => setShowEditBoardListDialog(false);
  const handleDeleteBoardListClose = () => setShowDeleteBoardListDialog(false);
  const handleAddBoardListCardClose = () => setShowAddBoardListCardDialog(false);
  const handleEditBoardListCardClose = () => { setShowEditBoardListCardDialog(false); }
  const handleDeleteBoardListCardClose = () => setShowDeleteBoardListCardDialog(false);

  const [boardListCardNetworks, setBoardListCardNetworks] = useState([]);

  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const handlerNetworksOnSelect = (selectedList, selectedItem) => {
    let selectedNetworkIds = selectedList.map(item => { return item.id; });
    setSelectedNetworks(selectedNetworkIds);
    setBoardListCardNetworks(props.networks.filter(i => selectedNetworkIds.includes(i.id)));
    selectedBoardListCard.selectedBoardListCardNetworks = selectedList;
  }
  
  const handleInviteDetails = (e) => {
    editBoardListCardNetworksMutation.mutate({
      id: selectedBoardListCard.id,
      networkIds: selectedNetworks,
    });
    setTimeout(function() {
      setShowViewerDialog(!showViewerDialog);
    }, 1000);

    e.preventDefault(); 
    e.stopPropagation();
  }

  const handleBoardDelete = () => {
    deleteBoardMutation.mutate({
      id: props.selectedBoard.id,
    });
    setShowDeleteBoardDialog(false);
    setShowEditBoardDialog(false);
    window.location.reload(false);
  };
  const handleEditBoardSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      editBoardMutation.mutate({
        id: props.selectedBoard.id,
        name: form.formBoardName.value,
      });

      props.selectedBoard.name = form.formBoardName.value;

      setShowEditBoardDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const handleBoardListDelete = () => {
    deleteBoardListMutation.mutate({
      id: selectedBoardList.id,
    });
    setShowDeleteBoardListDialog(false);
    setShowEditBoardListDialog(false);
  };

  const handleEditBoardListSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      editBoardListMutation.mutate({
        id: selectedBoardList.id,
        boardId: props.selectedBoard.id,
        name: form.formBoardListName.value,
      });

      setShowEditBoardListDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const handleAddBoardListSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      addBoardListMutation.mutate({
        boardId: props.selectedBoard.id,
        name: form.formBoardListName.value,
      });
      setShowAddBoardListDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const editBoardListOnClick = (boardList) => {
    if (boardList != null) {
      setSelectedBoardList(boardList);
      setShowEditBoardListDialog(true);
    }
  };
  const plusBoardListPositionOnClick = (boardList, i) => {
    positionBoardListMutation.mutate({
      id: boardList.id,
      boardId: props.selectedBoard.id,
      position: i + 1,
      step: 1
    });
  }
  const minusBoardListPositionOnClick = (boardList, i) => {
    positionBoardListMutation.mutate({
      id: boardList.id,
      boardId: props.selectedBoard.id,
      position: i + 1,
      step: -1
    });
  }
 
  const editBoardListCardOnClick = (boardListCard) => {
    if (boardListCard != null) {
      setSelectedBoardListCard(boardListCard);
      setLocation(boardListCard.location);
      setShowEditBoardListCardDialog(true);
      setCardPrice(currency(boardListCard.price));
      setBoardListCardComplete(boardListCard.complete);
      setBoardListCardNotify(boardListCard.notify); 
      setBoardListCardShareable(boardListCard.shareable); 
      setBoardListCardRepeat(boardListCard.repeat);
      setBoardListCardRepeatRemind(boardListCard.repeatRemind);
      setLatitude(boardListCard.latitude/10000000);
      setLongitude(boardListCard.longitude/10000000);

      let selectedNetworkIds = boardListCard.boardListCardNetworks.map(item => { return item.networkId; });
      setSelectedNetworks(selectedNetworkIds);
      setBoardListCardNetworks(props.networks.filter(i => selectedNetworkIds.includes(i.id)));

      if (!boardListCard.dateStart.includes("1900-01-01")) {
        setShowRepeat(true);
      }
      else {
        setShowRepeat(false);
      }
      if (boardListCard.repeat) {
        setShowRepeatRemind(true);
      }
      else {
        setShowRepeatRemind(false);
      }
    }
  };
  const handleAddBoardListCardSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      const id = guid();
      let formBoardListCardPrice = 0;
      let formBoardListCardDateStart = new Date(1900, 0, 1);
      let formBoardListCardDateEnd = new Date(1900, 0, 1);
      let lat = parseFloat(latitude);
      let lng = parseFloat(longitude);
      
      if (form.formBoardListCardPrice.value.length > 0) { formBoardListCardPrice = floatWithoutComma(form.formBoardListCardPrice.value) }
      if (form.formBoardListCardDateStart.value.length > 0) { formBoardListCardDateStart = form.formBoardListCardDateStart.value }
      if (form.formBoardListCardDateEnd.value.length > 0) { formBoardListCardDateEnd = form.formBoardListCardDateEnd.value }

      addBoardListCardMutation.mutate({
        id: id,
        boardListId: form.formBoardList.value,
        name: form.formBoardListCardName.value,
        description: form.formBoardListCardDescription.value,
        dateStart: formBoardListCardDateStart,
        dateEnd: formBoardListCardDateEnd,
        timeStart: form.formBoardListCardDateStartTime.value,
        timeEnd: form.formBoardListCardDateEndTime.value,
        price: formBoardListCardPrice,
        complete: boardListCardComplete,
        notify: boardListCardNotify,
        shareable: boardListCardShareable,
        location: location,
        latitude: parseInt(lat * 10000000),
        longitude: parseInt(lng * 10000000),
        repeat: boardListCardRepeat,
        repeatRemind: boardListCardRepeatRemind,
      });

      setTimeout(function() {
        editBoardListCardNetworksMutation.mutate({
          id: id,
          networkIds: selectedNetworks,
        });
      }, 500);

      setShowAddBoardListCardDialog(false);
      setShowRepeat(false);
      setShowRepeatRemind(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const handleEditBoardListCardSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      let formBoardListCardPrice = 0;
      let formBoardListCardDateStart = date(new Date(1900, 0, 1));
      let formBoardListCardDateEnd = date(new Date(1900, 0, 1));
      let repeat = boardListCardRepeat;
      let repeatRemind = boardListCardRepeatRemind;
      let lat = parseFloat(latitude);
      let lng = parseFloat(longitude);

      if (form.formBoardListCardPrice.value.length > 0) { formBoardListCardPrice = floatWithoutComma(form.formBoardListCardPrice.value) }
      if (form.formBoardListCardDateStart.value.length > 0) { formBoardListCardDateStart = form.formBoardListCardDateStart.value }
      else { setBoardListCardRepeat(false); setBoardListCardRepeatRemind(false); repeat = false; repeatRemind = false; }
      if (form.formBoardListCardDateEnd.value.length > 0) { formBoardListCardDateEnd = form.formBoardListCardDateEnd.value }

      editBoardListCardMutation.mutate({
        id: selectedBoardListCard.id,
        boardListId: form.formBoardList.value,
        name: form.formBoardListCardName.value,
        description: form.formBoardListCardDescription.value,
        dateStart: formBoardListCardDateStart,
        dateEnd: formBoardListCardDateEnd,
        timeStart: form.formBoardListCardDateStartTime.value,
        timeEnd: form.formBoardListCardDateEndTime.value,
        price: formBoardListCardPrice,
        complete: boardListCardComplete,
        notify: boardListCardNotify,
        shareable: boardListCardShareable,
        location: location,
        latitude: parseInt(lat * 10000000),
        longitude: parseInt(lng * 10000000),
        repeat: repeat,
        repeatRemind: repeatRemind,
      });

      setTimeout(function() {
        editBoardListCardNetworksMutation.mutate({
          id: selectedBoardListCard.id,
          networkIds: selectedNetworks,
        });
      }, 500);

      setShowEditBoardListCardDialog(false);
      setShowRepeat(false);
      setShowRepeatRemind(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const handleBoardListCardDelete = () => {
    deleteBoardListCardMutation.mutate({
      id: selectedBoardListCard.id,
    });
    setShowDeleteBoardListCardDialog(false);
    setShowEditBoardListCardDialog(false);
  };
  const addBoardListCardOnClick = (b) => {
    setSelectedBoardList(b);
    setCardPrice("");
    setValidated(false);
    setBoardListCardComplete(false); 
    setBoardListCardNotify(false); 
    setBoardListCardShareable(false);
    setBoardListCardRepeat(false); 
    setBoardListCardRepeatRemind(false); 
    setLocation("");
    setShowRepeat(false); 
    setShowRepeatRemind(false);
    setShowAddBoardListCardDialog(true);
    setLatitude(0);
    setLongitude(0);
  };
  const plusBoardListCardPositionOnClick = (boardListCard, i) => {
    positionBoardListCardMutation.mutate({
      id: boardListCard.id,
      boardListId: boardListCard.boardListId,
      position: i,
      step: 1
    });
  }
  const minusBoardListCardPositionOnClick = (boardListCard, i) => {
    positionBoardListCardMutation.mutate({
      id: boardListCard.id,
      boardListId: boardListCard.boardListId,
      position: i,
      step: -1
    });
  }
  const blurCardPrice = e => {
    if (e.target.value !== "") {
      let result = parseFloat(floatWithoutComma(e.target.value));
      if (!isNaN(result)) { setCardPrice(currency(result)); }
      else { setCardPrice(""); }
    }
  }
  const onChangeCardPrice = e => {
    setCardPrice(e.target.value);
  }
  const onChangeToggleRepeatGroup = e => {
    if (e.target.value.length > 0) {
      setShowRepeat(true);
    }
    else {
      setShowRepeat(false);
      boardListCardRepeat(false);
      boardListCardRepeatRemind(false);
    }
  }

  const handleSearch = (event) => {
    setSearch(event.target.value);
  }

  return (
    <div>
      <h1><Icon.CardChecklist size={30} /> {props.selectedBoard.name} <button className="button-text" onClick={() => setShowEditBoardDialog(true)}><Icon.ThreeDots size={40} /></button></h1>
      <p></p>

      <div className="scroll-panel">

        <div className='board-search'><Form.Control type="text" placeholder="Search..." onChange={handleSearch} /></div>

        {boardLists && boardLists.sort((a, b) => a.position > b.position ? 1 : -1).map((boardList, i) => {
          let listBalance = 0;
          let listTotal = 0;
          return (
            <div className={search.length > 0 && boardList.boardListCards.filter(m => m.name.toLowerCase().includes(search.toLowerCase())).length === 0 ? "hide" : "board-list-container" } key={boardList.id}>
              <div className="board-list">
                <div>{boardList.name}</div>
                <hr/>
                {search.length !== 0 ? <div></div> :
                  <div className="board-list-options">
                    {i !== 0 && (<button className="board-list-option" onClick={(e) => minusBoardListPositionOnClick(boardList, i)}><Icon.ChevronLeft size={30} /></button>)}
                    {i === 0 && (<button className="board-list-option" disabled>&nbsp;</button>)}
                    <button className="board-list-option" onClick={(e) => editBoardListOnClick(boardList)}><Icon.ThreeDots size={30} /></button>
                    {i !== boardLists.length - 1 && (<button className="board-list-option" onClick={(e) => plusBoardListPositionOnClick(boardList, i)}><Icon.ChevronRight size={30} /></button>)}
                    {i === boardLists.length - 1 && (<button className="board-list-option" disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>)}
                    <hr/>
                  </div> 
                }
                <div className="board-list-cards-container">
                {boardList.boardListCards && boardList.boardListCards.filter(m => m.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => a.position < b.position ? 1 : -1).map((boardListCard, j) => {
                  if (boardListCard.complete) { listBalance += boardListCard.price; }
                  listTotal += boardListCard.price;
                  return (
                  <div key={boardListCard.id} className="board-list-cards">
                    <div className="board-list-card" onClick={(e) => editBoardListCardOnClick(boardListCard, j)}>
                      {boardListCard.name}<hr></hr>
                      <div>
                        {boardListCard.dateStart.includes(1900) && boardListCard.dateEnd.includes(1900) && ""}
                        {!boardListCard.dateStart.includes(1900) && boardListCard.dateEnd.includes(1900) && (<div className="board-list-card-date">{date(boardListCard.dateStart)}<hr></hr></div>)}
                        {boardListCard.dateStart.includes(1900) && !boardListCard.dateEnd.includes(1900) && (<div className="board-list-card-date">{date(boardListCard.dateEnd)}<hr></hr></div>)}
                        {!boardListCard.dateStart.includes(1900) && !boardListCard.dateEnd.includes(1900) && (<div className="board-list-card-date">{date(boardListCard.dateStart)} to {date(boardListCard.dateEnd)}<hr></hr></div>)}
                        
                        <div className='row'>
                          <div className='col-6'>
                            {boardListCard.complete && <span title='Complete'><Icon.CheckCircle size={18} /></span>} 
                            {boardListCard.dateStart && !boardListCard.dateStart.includes("00:00:00") && <span title='Time Set'><Icon.ClockFill size={18} /></span>} 
                            {boardListCard.location && boardListCard.location.length > 0 && <span><Icon.GeoAltFill size={18} title={boardListCard.location} /></span>} 
                            {boardListCard.repeat && <span><Icon.ArrowCounterclockwise size={18} title="Repeat" /></span>} 
                            {boardListCard.repeatRemind && <span><Icon.FlagFill size={18} title="Important" /></span>} 
                            {boardListCard.notify && <span title='Send Notification'><Icon.EnvelopeFill size={18} /></span>} 
                            {boardListCard.boardListCardNetworks && boardListCard.boardListCardNetworks.length > 0 && <span title={`Invited (${boardListCard.boardListCardNetworks.length})`}><Icon.PeopleFill size={18} /></span>} 
                          </div>
                          <div className='col-6 text-right'>
                            {currency(boardListCard.price) !== "0.00" && (<div>${currency(boardListCard.price)}</div>)}
                          </div>
                        </div>
                      </div>
                    </div>
                    {search.length === 0 ?
                      <div className="board-list-card-options">
                        {j !== 0 && (<button className="board-list-card-option"><Icon.ChevronUp size={18} onClick={(e) => plusBoardListCardPositionOnClick(boardListCard, boardList.boardListCards.length - j)} /></button>)}
                        {j !== boardList.boardListCards.length - 1 && (<button className="board-list-card-option"><Icon.ChevronDown size={18} onClick={() => minusBoardListCardPositionOnClick(boardListCard, boardList.boardListCards.length - j)} /></button>)}
                      </div> : <div></div>
                    }
                  </div>
                  )
                  })}
                </div>
                <div>
                  {listTotal > 0 && (<div className="board-list-card-cost"><hr></hr>Paid ${currency(listBalance)} of <strong>${currency(listTotal)}</strong><hr></hr></div>)}                  
                  <button className="btn btn-primary" onClick={(e) => { addBoardListCardOnClick(boardList); }}>+ Add Card</button>
                  &nbsp;
                  {boardList.boardListCards.filter(item => item.location.length > 0).length > 0 && (<button className="btn btn-warning" onClick={() => { setSelectedBoardList(boardList); setShowBoardListMapDialog(true); }}><Icon.GlobeAmericas size={18}/> View Map</button>)}
                </div>
              </div>
            </div>
          )
        })}
        <div className="board-list-container" onClick={(e) => { setShowAddBoardListDialog(true);}}>
          <div className="board-list-add">+ Add List</div>
        </div>
      </div>

      {boardListsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
      {boardListsIsError && <div className="text-danger">Error loading boards: {boardListsError.message}</div>}

      <Modal
        size="lg"
        show={showEditBoardDialog}
        onHide={() => setShowEditBoardDialog(false)}
        aria-labelledby="modal-edit-board"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-board">Edit Board</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditBoardSubmit}>
            <Form.Group className="mb-3" controlId="formBoardName">
              <Form.Label>Board Name</Form.Label>
              <Form.Control required type="text" maxLength="50" placeholder="Enter an board name" defaultValue={props.selectedBoard.name} />
              <Form.Control.Feedback type="invalid">Please enter an board name.</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditBoardClose}>Cancel</Button>
            <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteBoardDialog(true)}>Delete</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteBoardDialog}
        onHide={() => setShowDeleteBoardDialog(false)}
        aria-labelledby="modal-delete"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteBoardDialog && <div><p>{props.selectedBoard.name}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleBoardDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteBoardClose}>No</Button>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showAddBoardListDialog}
        onHide={() => setShowAddBoardListDialog(false)}
        aria-labelledby="modal-add-board-list"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-add-board-list">Add Board List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddBoardListSubmit}>
            <Form.Group className="mb-3" controlId="formBoardListName">
              <Form.Label>Board List Name</Form.Label>
              <Form.Control required type="text" maxLength="100" placeholder="Enter an board list name" />
              <Form.Control.Feedback type="invalid">Please enter an board list name.</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleAddBoardListClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showEditBoardListDialog}
        onHide={() => setShowEditBoardListDialog(false)}
        aria-labelledby="modal-edit-board"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-board">Edit BoardList</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditBoardListSubmit}>
            <Form.Group className="mb-3" controlId="formBoardListName">
              <Form.Label>Board Name</Form.Label>
              <Form.Control required type="text" maxLength="50" placeholder="Enter an board name" defaultValue={selectedBoardList.name} />
              <Form.Control.Feedback type="invalid">Please enter an board name.</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditBoardListClose}>Cancel</Button>
            <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteBoardListDialog(true)}>Delete</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteBoardListDialog}
        onHide={() => setShowDeleteBoardListDialog(false)}
        aria-labelledby="modal-delete-board-list"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete-board-list">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteBoardListDialog && <div><p>{selectedBoardList.name}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleBoardListDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteBoardListClose}>No</Button>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showAddBoardListCardDialog}
        onHide={() => setShowAddBoardListCardDialog(false)}
        aria-labelledby="modal-add-board-list-card"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-add-board-list-card">Add Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddBoardListCardSubmit}>
            <Form.Group className="mb-3" controlId="formBoardList">
              <Form.Label>Board List</Form.Label>
              <Form.Select defaultValue={selectedBoardList.id}>
                {boardLists && (boardLists.sort((a, b) => a.position > b.position ? 1 : -1).map((m) => {return <option key={m.id} value={m.id}>{m.name}</option>}))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBoardListCardName">
              <Form.Label>Card Name</Form.Label>
              <Form.Control required type="text" maxLength="100" placeholder="Enter a card name" />
              <Form.Control.Feedback type="invalid">Please enter a card name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBoardListCardDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={6} maxLength="4000" placeholder="Enter a description" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBoardListCardPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control type="text" value={cardPrice} autoComplete="off" onBlur={blurCardPrice} onChange={onChangeCardPrice} placeholder="Enter a price" />
            </Form.Group>
            <div className='row'>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateStart">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" placeholder="Enter a start date" onChange={onChangeToggleRepeatGroup} />
              </Form.Group>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateStartTime">
                <Form.Label>Start Time</Form.Label>
                <Form.Control type="time" />
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateEnd">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" placeholder="Enter an end date" />
              </Form.Group>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateEndTime">
                <Form.Label>End Time</Form.Label>
                <Form.Control type="time" />
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardNotify">
                <Form.Label>Send Notifications</Form.Label>
                <Form.Check type="switch" onChange={(e) => {setBoardListCardNotify(!boardListCardNotify);}} />
              </Form.Group>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardShareable">
                <Form.Label>Shareable</Form.Label>
                <Form.Check type="switch" onChange={(e) => {setBoardListCardShareable(!boardListCardShareable);}} />
              </Form.Group>
            </div>
            <br></br><p></p>
            {showRepeat &&   
              <>
                <div className='row'>
                  <Form.Group className="mb-3 col-sm-6" controlId="formBoardListCardRepeat">
                    <Form.Label>Repeat Yearly</Form.Label>
                    <Form.Check type="switch" onChange={(e) => {setBoardListCardRepeat(!boardListCardRepeat); setShowRepeatRemind(!boardListCardRepeat); }} />
                  </Form.Group>
                  {showRepeatRemind &&
                    <Form.Group className="mb-3 col-sm-6" controlId="formBoardListCardRepeatRemind">
                      <Form.Label>Important</Form.Label>
                      <Form.Check type="switch" onChange={(e) => {setBoardListCardRepeatRemind(!boardListCardRepeatRemind);}} />
                    </Form.Group>
                  }
                </div>
                <br></br><p></p>
              </>
            }
            <Form.Group className="mb-3" controlId="formBoardListCardLocation">
              <Form.Label>Location</Form.Label>
              <Autocomplete className='form-control' maxLength="100" onKeyUp={(e) => setLocation(e.target.value)}
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                onPlaceSelected={(place) => {
                  setLatitude(place.geometry.location.lat());
                  setLongitude(place.geometry.location.lng());
                  setLocation(place.formatted_address);
                }}
                options={{
                    types: ["address"],
                    componentRestrictions: { country: "ca" },
                }}
            />          
            </Form.Group>
            {location && location.length > 0 && <div><iframe style={{width:"100%"}} src={'https://maps.google.com/maps?q=' + location.replace("#", "") + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe></div> }
            <p></p>
            <Form.Group className="mb-3" controlId="formBoardListCardNetworks">
              <Form.Label>Invites</Form.Label>
              <Multiselect
                displayValue="fullName"
                placeholder=""
                onKeyPressFn={function noRefCheck(){}}
                onRemove={handlerNetworksOnSelect}
                onSearch={function noRefCheck(){}}
                onSelect={handlerNetworksOnSelect}
                options={props.networks.filter(item => item.team === "").map(item => { return {"fullName": `${item.firstName} ${item.lastName}`, "id":item.id} })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBoardListCardComplete">
              <Form.Label>Is Complete</Form.Label>
              <Form.Check type="switch" onChange={(e) => {setBoardListCardComplete(!boardListCardComplete);}} />
            </Form.Group>
            <br></br><br></br>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleAddBoardListCardClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showEditBoardListCardDialog}
        onHide={() => setShowEditBoardListCardDialog(false)}
        aria-labelledby="modal-edit-board-list-card"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-board-list-card">Edit Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a className="btn-card-viewer" href={`/view/${selectedBoardListCard.id}`} target='_blank'><Icon.WindowFullscreen size={20}/></a>
          <Form noValidate validated={validated} onSubmit={handleEditBoardListCardSubmit}>
            <Form.Group className="mb-3" controlId="formBoardList">
              <Form.Label>Board List</Form.Label>
              <Form.Select defaultValue={selectedBoardListCard.boardListId}>
                {boardLists && (boardLists.sort((a, b) => a.position > b.position ? 1 : -1).map((m) => {return <option key={m.id} value={m.id}>{m.name}</option>}))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBoardListCardName">
              <Form.Label>Card Name</Form.Label>
              <Form.Control required type="text" maxLength="100" placeholder="Enter a card name" defaultValue={selectedBoardListCard.name} />
              <Form.Control.Feedback type="invalid">Please enter a card name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBoardListCardDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={6} maxLength="4000" placeholder="Enter a description" defaultValue={selectedBoardListCard.description} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBoardListCardPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control type="text" value={cardPrice} autoComplete="off" onBlur={blurCardPrice} onChange={onChangeCardPrice} placeholder="Enter a price" />
            </Form.Group>
            <div className='row'>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateStart">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" placeholder="Enter a start date" defaultValue={selectedBoardListCard.dateStart && !selectedBoardListCard.dateStart.includes("1900") && (date(selectedBoardListCard.dateStart))} onChange={onChangeToggleRepeatGroup} />
              </Form.Group>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateStartTime">
                <Form.Label>Start Time</Form.Label>
                <Form.Control type="time" defaultValue={selectedBoardListCard.dateStart && !selectedBoardListCard.dateStart.includes("1900") && !selectedBoardListCard.dateStart.includes("00:00:00") && time(selectedBoardListCard.dateStart)} />
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateEnd">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" placeholder="Enter an end date" defaultValue={selectedBoardListCard.dateEnd && !selectedBoardListCard.dateEnd.includes("1900") && (date(selectedBoardListCard.dateEnd))} />
              </Form.Group>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateEndTime">
                <Form.Label>End Time</Form.Label>
                <Form.Control type="time" defaultValue={selectedBoardListCard.dateEnd && !selectedBoardListCard.dateEnd.includes("1900") && !selectedBoardListCard.dateEnd.includes("00:00:00") && time(selectedBoardListCard.dateEnd)} />
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardNotify">
                <Form.Label>Send Notifications</Form.Label>
                <Form.Check type="switch" defaultChecked={selectedBoardListCard.notify} onChange={(e) => {setBoardListCardNotify(!boardListCardNotify);}} />
              </Form.Group>
              <Form.Group className="mb-3 col-6" controlId="formBoardListCardShareable">
                <Form.Label>Shareable</Form.Label>
                <Form.Check type="switch" defaultChecked={selectedBoardListCard.shareable} onChange={(e) => {setBoardListCardShareable(!boardListCardShareable);}} />
              </Form.Group>
            </div>
            <br></br><p></p>
            {showRepeat &&   
              <>
                <div className='row'>
                  <Form.Group className="mb-3 col-6" controlId="formBoardListCardRepeat">
                    <Form.Label>Repeat Yearly</Form.Label>
                    <Form.Check type="switch" defaultChecked={selectedBoardListCard.repeat} onChange={(e) => {setBoardListCardRepeat(!boardListCardRepeat); setShowRepeatRemind(!boardListCardRepeat); setBoardListCardRepeatRemind(false); }} />
                  </Form.Group>
                  {showRepeatRemind &&
                    <Form.Group className="mb-3 col-6" controlId="formBoardListCardRepeatRemind">
                      <Form.Label>Important</Form.Label>
                      <Form.Check type="switch" defaultChecked={selectedBoardListCard.repeatRemind} onChange={(e) => {setBoardListCardRepeatRemind(!boardListCardRepeatRemind);}} />
                    </Form.Group>
                  }
                </div>
                <br></br><p></p>
              </>
            }
            <Form.Group className="mb-3" controlId="formBoardListCardLocation">
              <Form.Label>Location</Form.Label>
              <Autocomplete className='form-control' maxLength="100" onKeyUp={(e) => setLocation(e.target.value)}
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={(place) => {
                    setLatitude(place.geometry.location.lat());
                    setLongitude(place.geometry.location.lng());
                    setLocation(place.formatted_address);
                  }}
                  options={{
                      types: ["address"],
                      componentRestrictions: { country: "ca" },
                  }}
                  defaultValue={selectedBoardListCard.location}
              />     
            </Form.Group>
            {location && location.length > 0 && <div><iframe style={{width:"100%"}} src={'https://maps.google.com/maps?q=' + location.replace("#", "") + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe></div> }
            <p></p>
            <Form.Group className="mb-3" controlId="formBoardListCardNetworks">
              <Form.Label>Invites</Form.Label>
              <Multiselect
                displayValue="fullName"
                placeholder=""
                onKeyPressFn={function noRefCheck(){}}
                onRemove={handlerNetworksOnSelect}
                onSearch={function noRefCheck(){}}
                onSelect={handlerNetworksOnSelect}
                selectedValues={boardListCardNetworks.map(item => { return {"fullName": `${item.firstName} ${item.lastName}`, "id":item.id} })}
                options={props.networks.filter(item => item.team === "").map(item => { return {"fullName": `${item.firstName} ${item.lastName}`, "id":item.id} })}
              />
            </Form.Group>
            {selectedNetworks.length > 0 && <p><button className='btn btn-warning' onClick={handleInviteDetails}>Invites Details</button></p> }
            <Form.Group className="mb-3" controlId="formBoardListCardComplete">
              <Form.Label>Is Complete</Form.Label>
              <Form.Check type="switch" defaultChecked={selectedBoardListCard.complete} onChange={(e) => {setBoardListCardComplete(!boardListCardComplete);}} />
            </Form.Group>
            <br/><br/>      
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditBoardListCardClose}>Cancel</Button>
            <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteBoardListCardDialog(true)}>Delete</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteBoardListCardDialog}
        onHide={() => setShowDeleteBoardListCardDialog(false)}
        aria-labelledby="modal-delete-board-list-card"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete-board-list-card">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteBoardListCardDialog && <div><p>{selectedBoardListCard.name}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleBoardListCardDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteBoardListCardClose}>No</Button>
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        show={showBoardListMapDialog}
        onHide={() => setShowBoardListMapDialog(false)}
        aria-labelledby="modal-board-list-map"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-board-list-map">Board List Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>{selectedBoardList.name}</h2>
            {selectedBoardList.boardListCards && selectedBoardList.boardListCards.length > 0 ?
              <div className='map-container'>
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['marker']}>
                  <Map
                    mapId={'bf51a910020fa25a'}
                    defaultZoom={10}
                    defaultCenter={defaultPosition}
                    gestureHandling={'greedy'}
                    disableDefaultUI>
                    {selectedBoardList.boardListCards.filter(item => item.location.length > 0).map(item => {
                      if (item.latitude === 0 && item.longitude === 0) { return ""; }
                      return <MarkerWithInfoWindow key={item.id} locations={selectedBoardList.boardListCards.filter(item => item.location.length > 0)} location={{"type":"board", "name": `${item.name}`, "address": item.location, "latitude": item.latitude, "longitude": item.longitude}} />
                    })}
                  </Map>
                </APIProvider>
              </div>
            : <></>
            }

          <hr></hr>
          <Button variant="secondary" type="button" onClick={() => setShowBoardListMapDialog(false)}>Close</Button>
        </Modal.Body>
      </Modal>

      <Modal
          size="xl"
          show={showViewerDialog}
          onHide={() => setShowViewerDialog(false)}
          aria-labelledby="modal-viewer"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-viewer">Invites Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ManageBoardNetworks selectedBoardListCard={selectedBoardListCard} selectedNetworks={selectedNetworks} networks={props.networks}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}