import React, { useState } from 'react';
import { highlightBoard } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';
import { ManageProfileLocationHistoryCalendar, ManageProfileLocationHistoryMap, ManageProfileLocationHistoryUpload } from 'sections';

export { Locations };

function Locations() {
    const categories = [{ id: 1, title: "Location Calendar"}, {id: 2, title: "Location Calendar" }, {id: 3, title: "Location Upload" }];
    const [category, setCategory] = useState(categories[0]);

    const handleContent = (e, id) => {
        highlightBoard(e);
        setCategory(categories.filter(item => item.id === id)[0]);
    }

    return (
        <>
            <div className='panel'>
                <h1><Icon.GeoAltFill size={35} /> Location History</h1>
                <p></p>
                <hr></hr>
                <p></p>
                <button className='btn btn-primary' onClick={(e)=> handleContent(e, 1)}><Icon.Calendar3 size={20}/> Calendar</button>
                &nbsp; &nbsp;
                <button className='btn btn-primary' onClick={(e)=> handleContent(e, 2)}><Icon.GeoAltFill size={20}/> Map</button>
                &nbsp; &nbsp;
                <button className='btn btn-primary' onClick={(e)=> handleContent(e, 3)}><Icon.CloudUpload size={20}/> Upload</button>
                <hr/>
                {category && category.id === 1 && (
                    <ManageProfileLocationHistoryCalendar/>
                )}
                {category && category.id === 2 && (
                    <ManageProfileLocationHistoryMap/>
                )}
                {category && category.id === 3 && (
                    <ManageProfileLocationHistoryUpload/>
                )}
            </div>
        </>
    );
}
