import { useEffect, useState } from 'react';
import { useBackupRefresh } from 'hooks/accounts.hook';

export { Uptime };

function Uptime() {
    const { data: refreshData } = useBackupRefresh();
    const [message, setMessage] = useState('');
    const [count, setCount] = useState(0);

    useEffect(() => { 
        setTimeout(function(){
            setCount(refreshData);
            if (refreshData > 0) {
                setMessage('success');
            }
        },1000);
    }, [refreshData]);

    return (
        <div className="container">
            <p></p>
            <p>{message}</p>
        </div>
    )
}
