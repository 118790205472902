import React, { useState, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { getUser } from 'helpers/current-user';
import { useUploadToLocation } from 'hooks/locations.hook';
import * as Icon from 'react-bootstrap-icons';

export { ManageProfileLocationHistoryUpload };

function ManageProfileLocationHistoryUpload() {
  const [isUploading, setIsUploading] = useState(false);
  const [user] = useState(getUser());
  const uploadToLocation = useUploadToLocation();
  const [yearRange, setYearRange] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState("0" + (new Date().getMonth() + 1).toString().slice(-2));

  useEffect(() => {
      var currentYear = new Date().getFullYear(), years = [];
      var startYear = 2010;  
      while (startYear <= currentYear) {
          years.push(currentYear--);
      }   
      setYearRange(years);
  }, []);

  const handleJsonUpload = (files) => {
    let hasUploads = false;
    let timeout = 0;
    let formData = new FormData();
    formData.append(`UserId`, user.id);
    formData.append(`Filter`, selectedYear + "-" + selectedMonth);
    [...files].forEach((file, i) => {
      hasUploads = true;
      formData.append(`Files[${i}].Id`, user.id);
      formData.append(`Files[${i}].File`, file);
    });
    if (hasUploads) {
      setTimeout(function () {
        uploadToLocation.mutate({
          formData: formData
        });
        timeout = 20000;
        setIsUploading(true);
        setTimeout(function () {
          window.location.href = "/locations";
        }, timeout);
      }, 500);
    }
  };

  return (
    <div>
      <p><Icon.CloudArrowUpFill size={30}/>&nbsp; Upload Timeline Data: <span className='directory'><Icon.Android/> Settings - Location - Location Services - Timeline - Export Timeline Data</span></p>
      <Form.Group className="mb-3" controlId="formFile">
          <div className='row col-md-3'>
            <Form.Select defaultValue={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
              {yearRange && yearRange.map((m) => { return <option key={m} value={m}>{m}</option>})}
            </Form.Select>
          </div>
          <p></p>
          <div className='row col-md-3'>
            <Form.Select defaultValue={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
              <option key={1} value={'01'}>January</option>
              <option key={2} value={'02'}>February</option>
              <option key={3} value={'03'}>March</option>
              <option key={4} value={'04'}>April</option>
              <option key={5} value={'05'}>May</option>
              <option key={6} value={'06'}>June</option>
              <option key={7} value={'07'}>July</option>
              <option key={8} value={'08'}>August</option>
              <option key={9} value={'09'}>September</option>
              <option key={10} value={'10'}>October</option>
              <option key={11} value={'11'}>November</option>
              <option key={12} value={'12'}>December</option>
            </Form.Select>
          </div>
          <p></p>
          <div className='row col-md-3'>
            <Form.Control type="file" accept=".json" onChange={(e) => { handleJsonUpload(e.target.files); }} />
          </div>
      </Form.Group>
      <p></p>
      {isUploading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}

    </div>
  );
}